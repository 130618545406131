<template>
	<div id="details" :class="!te404?'deta':''">
         <div v-if="!te404" >
			 <!-- <div class="h1">{{cideData.title}}</div> -->
			 <div class="top">
				 <div class="h1">查看详细解析</div>
				 <div class="ul">
				 	<div :class="'li '+ (sybData[0] ==true?'li_yes':'')" @click="go(0)" >1</div>
				 	<div :class="'li '+ (sybData[1] ==true?'li_yes':'')" @click="go(1)">2</div>
				 	<div :class="'li '+ (sybData[2] ==true?'li_yes':'')" @click="go(2)">3</div>
				 	<div :class="'li '+ (sybData[3] ==true?'li_yes':'')" @click="go(3)">4</div>
				 	<div :class="'li '+ (sybData[4] ==true?'li_yes':'')" @click="go(4)">5</div>
				 	<!-- <img class="iocn" src="../assets/iocn.png" alt=""> -->
				 </div>
			 </div>
			 <div class="contD">
				 <div class="h1 h2">答案速览</div>
				 <div class="html" v-html="cideData.content"></div>
			 </div>
			 
		 </div>



		<div v-if="Loadings" class="Loadings">
			<van-loading size="24px" vertical>
				<div>加载中...</div>
			</van-loading>
		</div>
		<img v-if="te404" src="../assets/404.svg" alt="">
	</div>

</template>

<script>
	import {
		Notify,
		Cell,
		CellGroup,
		Field,
		Icon,
		Loading,
	} from 'vant';

	import https from '../https.js'
	export default {
		components: {
			[Notify.name]: Notify,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {

			return {
				html: "",
				Loadings: false,
				te404: false,
				cideData: {
					title: "",
					content: ""
				},
				detailData: [],
				sybData:[],
			}
		},
		created() {
			// localStorage   长期
			// sessionStorage; 短期
			// var storage = window.sessionStorage;
			// storage.setItem('tab', tab);
			// storage.getItem('tab')
			// s

			let id = this.$route.params.id
			// console.log(id)
			this.id  = id
			var that = this;
			var detailData = [];
			that.Loadings = true;
			https.fetchGet('/api/subject/parse/find/' + id, {}).then(res => {
				that.Loadings = false;
				if (res.data && res.data.data) {
					var data = res.data.data
					detailData.push(data.ctx1)
					detailData.push(data.ctx2)
					detailData.push(data.ctx3)
					detailData.push(data.ctx4)
					detailData.push(data.ctx5)
					that.detailData = res.data.data;
					that.cideData = data;
					var storage = window.sessionStorage;
					storage.setItem('detailData_' + id, JSON.stringify(detailData));
					// 
					// document.title = data.title;
					// console.log("detailData222",JSON.parse( storage.getItem('detailData_' + id)))
				} else {
					that.te404 = true
					Notify({
						type: 'warning',
						message: '文章已删除！'
					});
				}
			}).catch(err => {
				that.Loadings = false
				that.te404 = true
				Notify({
					type: 'warning',
					message: '当前人数过多，请稍后重试'
				});
				console.log(err)

			});
			var storage = window.localStorage;
			var a = storage.getItem('currentData_'+this.id);
			console.log("currentData_",typeof a,a)
			
			if(a){
				this.sybData = JSON.parse(a)||[]
			}

		},
		methods: {
			go(e){
				this.$router.push({
					name: 'subject/parse/details',
					query: {
						id: this.id,
						current:e
					}
				})
			},
		}
	}
</script>

<style scoped="scoped">
	#details {
		max-width: 750px;
		margin: 0 auto;
		
		
	}
	.contD{
		width: 690px;
		margin: 30px auto;
		background-color: #fff;
		border-radius: 28px;
	}
	.deta{
		background: url(../assets/b.png) no-repeat #F8F8F8;
		background-size:100%;
		overflow: hidden;
		min-height: 100vh;
	}
	.top{
		width: 690px;
		border-radius: 28px;
		background: url(../assets/b2.png) no-repeat #fff;
		background-size:100% 100%;
		margin: 46px auto 0;
		
	}
	.html {
		padding: 4px 30px 30px;
		font-size: 16px;
		/* text-align: justify; */
		word-wrap: break-word;
	}
	.html p{
		/* text-align-last:justify */
	}

	.h1 {
		padding: 50px 30px;
		font-size: 36px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #7EB49C;
	}
	.h2{
		padding: 46px 30px;
	}
	.h1:before{
	  content:" ";
	  width: 88px;
	  height: 4px;
	  display: block;
	  background: linear-gradient(90deg, rgba(126, 180, 156, 0) 0%, #7EB49C 100%);
	  border-radius: 2px;
	  margin-right: 20px;
	
	}
	.h1:after{
	  content:" ";
	  width: 88px;
	  height: 4px;
	  display: block;
	  background: linear-gradient(270deg, rgba(126, 180, 156, 0) 0%, #7EB49C 100%);
	  border-radius: 2px;
	  margin-left: 20px;
	}

	.Loadings {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		align-items: center;


	}

	.Loadings .van-loading {
		padding: 20px;
		background-color: rgba(0, 0, 0, 0.6);
		border-radius: 10px;
		color: #fff;


	}

	.Loadings .van-loading>div {
		color: #fff;
	}

	.ul {
		/* margin: 0 30px 48px; */
		/* border-radius: 18px; */
		/* border: 1px dashed #FF7F8D; */
		position: relative;
		padding: 0px 30px 84px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.li {
		width: 108px;
		height: 108px;
		background: #7EB49C;
		/* background-color: #FF4B5F; */
		border-radius: 12px;
		font-size: 34px;
		color: #fff;
		text-align: center;
		line-height: 108px;
		/* margin: 15px 9px; */
	}

	.li_yes {
		opacity: 0.7;
	}

	.iocn {
		width: 168px;
		height: 138px;
		position: absolute;
		bottom: -7px;
		right: -6px;
	}
</style>
